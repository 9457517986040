<template>
  <div class="obody">
    <HomePageTop></HomePageTop>

    <UserAgreement ref="UserAgreement"></UserAgreement>

    <div class="registerMainBox" v-loading.fullscreen.lock="loading">
      <!-- 注册头部 -->
      <div class="logo-box main">
        <h2 class="fl">{{ $fanyi('注册') }}</h2>
        <div class="fl fenge"></div>
      </div>

      <div class="register" v-if="step == 1">
        <div>
          <div>
            <h3 class="firstStepTitle">
              <p class="xuYaoXinXi">{{ $fanyi('填写信息') }}</p>
              <p class="Toast">
                {{ $fanyi('请将您的个人信息填入下方') }}
              </p>
            </h3>
            <!--            <div>Google注册</div>-->
          </div>
          <el-form class="firStepForm" :model="formregister" :rules="formRules" ref="formRef">
            <ul>
              <li>
                <!-- 登录名 -->
                <el-form-item prop="user_last_name" :label="$fanyi('姓氏')">
                  <input class="ipt" v-bind:class="{
                    istrueInput: formInputTrue.user_last_name,
                  }" @blur="validateField('user_last_name')" autocomplete="new-password"
                    v-model="formregister.user_last_name" :placeholder="$fanyi('请输入姓氏')" />
                  <div class="gou" v-if="formInputTrue.user_last_name">✔</div>
                </el-form-item>
              </li>
              <li>
                <!-- 名字 -->
                <el-form-item prop="user_name" :label="$fanyi('名字')">
                  <input v-bind:class="{
                    istrueInput: formInputTrue.user_name,
                  }" class="ipt" @blur="validateField('user_name')" v-model="formregister.user_name"
                    :placeholder="$fanyi('请输入名字')" />
                  <div class="gou" v-if="formInputTrue.user_name">✔</div>
                </el-form-item>
              </li>
              <li>
                <!-- 国家 -->
                <el-form-item :label="$fanyi('州')" prop="state">
                  <el-select class="xuanZeGuoJia" name="" v-bind:class="{
                    istrueInput: formInputTrue.state,
                  }" filterable @change="validateField('state')" @blur="validateField('state')"
                    v-model="formregister.state" :placeholder="$fanyi('请选择地区')">
                    <el-option :label="item" :value="item" v-for="(item, index) in $imdata.provincesList"
                      :key="item + index">
                    </el-option>
                  </el-select>
                  <div class="gou" v-if="formInputTrue.state" style="margin-right: 20px">
                    ✔
                  </div>
                </el-form-item>
              </li>



              <li class="guoJiaQuHao">
                <!-- 电话 -->
                <el-form-item :label="$fanyi('手机号')" prop="mobile">

                  <div class="shouJIHaoCon">
                    <div class="quHao" v-bind:class="{
                      istrueInput: formInputTrue.mobile,
                    }">
                      <el-select v-model="formregister.countryPhone">
                        <el-option v-for="item in Areacodelist" :key="item.sx" :label="item.value" :value="item.value">
                          {{ $store.state.languagetype == "English" ? item.sx + ' ' + item.value : item.key + ' ' +
                            item.value }}
                        </el-option>
                      </el-select>
                    </div>
                    <input @blur="validateField('mobile')" class="iptSmall" v-bind:class="{
                      istrueInput: formInputTrue.mobile,
                    }" oninput="this.value=this.value.replace(/[^\d]/g,'')" v-model="formregister.mobile"
                      :placeholder="this.$fanyi('请输入您的手机号')" />
                    <div class="gou" v-if="formInputTrue.mobile">✔</div>
                  </div>
                </el-form-item>
              </li>
              <li>
                <!-- 电子邮件 -->
                <el-form-item prop="email" :label="$fanyi('邮箱')">
                  <input class="ipt" v-bind:class="{
                    istrueInput: formInputTrue.email,
                  }" @blur="validateField('email')" v-model="formregister.email" :placeholder="$fanyi('请输入邮箱')" />
                  <div class="gou" v-if="formInputTrue.email">✔</div>
                </el-form-item>
              </li>
              <li class="yanzhengma">
                <!-- 验证码 -->
                <el-form-item :label="$fanyi('验证码')" prop="num">
                  <div class="yanzhengmaBox">
                    <div class="input">
                      <input v-bind:class="{
                        istrueInput: !!formInputTrue.num,
                      }" class="iptSmall" @blur="validateField('num')" v-model="formregister.num"
                        :placeholder="this.$fanyi('请输入验证码')" />
                      <div class="gou regCodegou" v-if="formInputTrue.num && show === true">
                        ✔
                      </div>
                      <div class="gou newRegCodegou" v-if="formInputTrue.num && show === false">
                        ✔
                      </div>
                    </div>
                    <p @click.prevent="sendSMS" v-show="show">
                      <!-- 发送验证码 -->{{ $fanyi('发送验证码') }}
                    </p>
                    <p v-show="!show" @click.prevent style="opacity: 0.6">
                      {{ Countdown }} S
                    </p>
                    <!-- 选择框 -->
                    <div class="Selectway">
                      <el-select v-model="formregister.tools" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </el-form-item>
              </li>
              <li>
                <!-- 密码 -->
                <el-form-item prop="password" :label="$fanyi('密码')" class="regerror">
                  <el-input type="password" class="ipt passWordInputBox" v-bind:class="{
                    istrueInput: formInputTrue.password,
                  }" @blur="validateField('password')" autocomplete="new-password" show-password
                    @input="passwordChannelInputLimit" v-model="formregister.password" :placeholder="$fanyi('请输入密码')" />
                </el-form-item>
              </li>
              <li>
                <!-- 密码确认 -->
                <el-form-item prop="repassword" :label="$fanyi('密码确认')">
                  <el-input class="ipt passWordInputBox" type="password" v-bind:class="{
                    istrueInput: formInputTrue.repassword,
                  }" @blur="validateField('repassword')" @input="repasswordChannelInputLimit"
                    autocomplete="new-password" show-password v-model="formregister.repassword"
                    :placeholder="$fanyi('请再次输入密码')" />
                </el-form-item>
              </li>
              <!-- <li>
                <el-form-item :label="$fanyi('SSN(选填)')" prop="user_id_card">
                  <input class="ipt" v-bind:class="{
                    istrueInput:
                      formInputTrue.user_id_card &&
                      ssnStatus === true &&
                      formregister.user_id_card != undefined,
                  }" @blur="validateField('user_id_card')" v-model="formregister.user_id_card"
                    :placeholder="$fanyi('请输入SSN')" />
                  <div class="gou" v-if="formInputTrue.user_id_card &&
                    ssnStatus === true &&
                    formregister.user_id_card != undefined
                  ">
                    ✔
                  </div>
                </el-form-item>
              </li> -->

              <li class="xieYi">
                <div class="fuWuXieYi">
                  <el-checkbox v-model="agreement"></el-checkbox>
                  <a href="javascript:;">
                    <span style="border-bottom:1px  solid transparent; margin:0 5px ;">
                      {{
                        $fanyi('RAKUMART')
                      }}</span>
                    <span @click="protocol" class="protocol">
                      {{
                        $fanyi('的用户协议和隐私政策')
                      }}
                    </span>
                  </a>
                </div>
                <button class="nextBtn" @click.prevent="getNext">
                  {{
                    $fanyi('同意以上协议，进入下一步')
                  }}
                </button>

                <!-- <el-form-item label="" class="xieYi">
                 
                </el-form-item> -->
              </li>
            </ul>
          </el-form>
        </div>
      </div>
      <!-- 第二步页面 -->
      <!--      <div class="registerInformation register" v-if="step == 2">-->
      <!--        &lt;!&ndash; <div class="registerInformationCon">-->
      <!--        <p class="registerInformationTitle">{{ $fanyi("个人信息") }}</p>-->
      <!--        <div class="registerInformationOpt">-->
      <!--          <label>{{ $fanyi("名字") }}：</label-->
      <!--          ><span>{{ formregister.user_last_name }}</span>-->
      <!--        </div>-->
      <!--        <div class="registerInformationOpt">-->
      <!--          <label>{{ $fanyi("登录名") }}：</label-->
      <!--          ><span>{{ formregister.user_name }}</span>-->
      <!--        </div>-->

      <!--        <div class="registerInformationOpt">-->
      <!--          <label>{{ $fanyi("密码") }}：</label-->
      <!--          ><span>{{ formregister.password }}</span>-->
      <!--        </div>-->
      <!--        <div class="registerInformationOpt">-->
      <!--          <label>{{ $fanyi("电子邮箱") }}：</label-->
      <!--          ><span>{{ formregister.email }}</span>-->
      <!--        </div>-->
      <!--        <div class="registerInformationOpt">-->
      <!--          <label>{{ $fanyi("电话") }}：</label-->
      <!--          ><span>{{ formregister.mobile }}</span>-->
      <!--        </div>-->

      <!--        <div class="registerInformationOpt">-->
      <!--          <button @click="step = 1">{{ $fanyi("返回重新编辑") }}</button>-->
      <!--          <button @click="formRegister()">{{ $fanyi("个人信息确认") }}</button>-->
      <!--        </div>-->
      <!--      </div> &ndash;&gt;-->
      <!--        <div>-->
      <!--          <h3 class="firstStepTitle">-->
      <!--            <p class="xuYaoXinXi">{{ $fanyi("填写信息") }}</p>-->
      <!--          </h3>-->
      <!--          <el-form-->
      <!--            class="firStepForm"-->
      <!--            :model="formregister"-->
      <!--            :rules="formRules"-->
      <!--            ref="formRef"-->
      <!--          >-->
      <!--            <ul>-->
      <!--              <li>-->
      <!--                &lt;!&ndash; 登录名 &ndash;&gt;-->
      <!--                <el-form-item prop="user_last_name" :label="$fanyi('姓氏')">-->
      <!--                  <div class="showregisterInfo">-->
      <!--                    {{ formregister.user_last_name }}-->
      <!--                  </div>-->
      <!--                </el-form-item>-->
      <!--              </li>-->
      <!--              <li>-->
      <!--                &lt;!&ndash; 名字 &ndash;&gt;-->
      <!--                <el-form-item prop="user_name" :label="$fanyi('名字')">-->
      <!--                  <div class="showregisterInfo">-->
      <!--                    {{ formregister.user_name }}-->
      <!--                  </div>-->
      <!--                </el-form-item>-->
      <!--              </li>-->

      <!--              <li>-->
      <!--                &lt;!&ndash; 国家 &ndash;&gt;-->
      <!--                <el-form-item :label="$fanyi('州')" prop="country">-->
      <!--                  <div class="showregisterInfo">-->
      <!--                    {{ formregister.country }}-->
      <!--                  </div>-->
      <!--                </el-form-item>-->
      <!--              </li>-->
      <!--              <li>-->
      <!--                &lt;!&ndash; 电子邮件 &ndash;&gt;-->
      <!--                <el-form-item prop="email" :label="$fanyi('邮箱')">-->
      <!--                  <div class="showregisterInfo">-->
      <!--                    {{ formregister.email }}-->
      <!--                  </div>-->
      <!--                </el-form-item>-->
      <!--              </li>-->
      <!--              <li class="guoJiaQuHao">-->
      <!--                &lt;!&ndash; 电话 &ndash;&gt;-->
      <!--                <el-form-item :label="$fanyi('手机号')" prop="mobile">-->
      <!--                  &lt;!&ndash; <select-->
      <!--                  name=""-->
      <!--                  v-model="formregister.countryPhone"-->
      <!--                  class="phone"-->
      <!--                >-->
      <!--                  <option value="+34">{{ $fanyi("西班牙") }}</option>-->
      <!--                </select> &ndash;&gt;-->
      <!--                  <div class="shouJIHaoCon">-->
      <!--                    <div-->
      <!--                      class="quHao"-->
      <!--                      style="-->
      <!--                        border: 1px solid #dedede;-->
      <!--                        background-color: #f3f3f3;-->
      <!--                      "-->
      <!--                    >-->
      <!--                      {{ formregister.countryPhone }}-->
      <!--                    </div>-->
      <!--                    <div class="showregisterInfo">-->
      <!--                      {{ formregister.mobile }}-->
      <!--                    </div>-->
      <!--                  </div>-->
      <!--                </el-form-item>-->
      <!--              </li>-->
      <!--              <li>-->
      <!--                &lt;!&ndash; 身份证 &ndash;&gt;-->
      <!--                <el-form-item prop="user_id_card" label="SSN">-->
      <!--                  <div class="showregisterInfo">-->
      <!--                    {{ formregister.user_id_card }}-->
      <!--                  </div>-->
      <!--                </el-form-item>-->
      <!--              </li>-->
      <!--              <li>-->
      <!--                &lt;!&ndash; 密码 &ndash;&gt;-->
      <!--                <el-form-item prop="password" :label="$fanyi('密码')">-->
      <!--                  <div class="showregisterInfo flexAndCenter" v-if="showPassword">-->
      <!--                    <div v-for="(item,index) in formregister.password.length" :key="index">●</div>-->
      <!--                  </div>-->
      <!--                  <div class="showregisterInfo" v-else>-->
      <!--                    {{ formregister.password }}-->
      <!--                  </div>-->
      <!--                  <i-->
      <!--                    class="el-icon-view allDataShow"-->
      <!--                    @click="showPassword = !showPassword"-->
      <!--                  ></i>-->
      <!--                </el-form-item>-->
      <!--              </li>-->
      <!--            </ul>-->
      <!--          </el-form>-->
      <!--          <div class="caoZuo">-->
      <!--            <button class="shangYiBu" @click.prevent="step = 1">-->
      <!--              {{ $fanyi("返回重新编辑") }}-->
      <!--            </button>-->
      <!--            <button class="xiaYiBu" @click.prevent="formRegister()">-->
      <!--              {{ $fanyi("个人信息确认") }}-->
      <!--            </button>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!-- 第三步页面 -->
      <div class="registration_complete" v-if="step == 2">
        <div class="registration_completeCon">
          <div class="registration_completeConOpt">
            <img class="successImg" src="../../assets/login/login_success.png" alt="" />
          </div>
          <div class=" showSpan">
            <p style="font-size: 24px;
                                                                                                font-family: Roboto;
                                                                                                font-weight: bold;
                                                                                                 color: #222222;
                                                                                                 margin-bottom: 5px;">
              {{ $fanyi('帐号注册完成') }}
            </p>

            <p style="font-size:18px;
                                                                                                font-family: Roboto;
                                                                                               font-weight: 400;
                                                                                                color: #999999;
                                                                                                margin-bottom: 60px;">
              {{ $fanyi('非常感谢您的注册') }}
            </p>
          </div>
          <div class="registration_completeConOpt">
            <button @click="$fun.toPage('/login')" class="log">
              {{ $fanyi('去登录') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- 图形验证码弹层 -->
    <el-dialog :visible.sync="imgdialogVisible" id="imgdialog" :close-on-click-modal="false" width="30%">

      <div class="header-box">
        <div class="imgbox"><img :src="imgurl" alt=""></div>

        <div class="Acquire" @click="sendSMS">{{ $fanyi('重置') }}</div>


      </div>

      <div class="inputbox">

        <el-input v-model="captcha"></el-input>
        <div @click="cofirmsend" class="cofirm">{{ $fanyi('确认') }}</div>
      </div>


    </el-dialog>
  </div>
</template>

<script>
import provincesList from '../../utlis/allImportData'
import HomePageTop from '../../components/head/HomePageTop.vue'
import axios from 'axios'
import Foot from '../../components/foot/Foot.vue'
import UserAgreement from './UserAgreement.vue'
import Areacode from '../../utlis/mobilephoneareacode.js'

export default {
  name: 'register',
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$fanyi('请再次输入密码')))
      } else if (value !== this.formregister.password) {
        callback(new Error(this.$fanyi('密码输入不一致')))
      } else {
        callback()
      }
    }
    // 字段校验
    var FieldCalibration = (rule, value, callback) => {
      this.$api
        .checkRegisterField({
          field: rule.field,
          value: value,
        })
        .then((res) => {
          if (res.success == false) {
            callback(new Error(this.$fanyi('已被使用')))
          } else {
            callback()
          }
        })
    }
    // 邮箱格式检验
    var emailFormatVerify = (rule, value, callback) => {

      let emailPostfixList = [
        'qq.com',
        '163.com',
        'fastmail.com',
        'me.com',
        'email.com',
        'gmail.com',
        '126.com',
        'yahoo.com',
        'sina.com',
        'games.com',
        'rocketmail.com',
        'wow.com',
        'googlemail.com',
        'comcast.net',
        'warwick.net',
        'comcast.net',
        'twcny.rr.com',
        'hotmail.com',
        'foxmail.com',
        'rakumartwork.com',
        'outlook.com',
        'facebook.com',
        'mail.com',
        'mac.com',
        'icloud.com',
        'zoho.com',
        'ymail.com',
        'ygm.com',
        'love.com',
        'aol.com',
        'verizon.net',
        'cs.com',
        'netzero.net',
        'rakumart.eu',
      ]
      let index = value.indexOf('@')
      let str = value.substr(index + 1, value.length)
      if (emailPostfixList.indexOf(str) === -1) {

        callback(new Error(this.$fanyi('请输入正确的邮箱地址')))
      } else {
        callback()
      }
    }
    // 校验SSN
    // var verificationText = (rule, value, callback) => {
    //   if (value === '' || value == undefined) {
    //     this.ssnStatus = false
    //     callback()
    //   } else if (value.trim() === '') {
    //     this.ssnStatus = false
    //     callback()
    //   } else if (value.trim() !== '') {
    //     let str = /^(\w){9}$/
    //     if (!str.test(value)) {
    //       this.ssnStatus = false
    //       callback(new Error(this.$fanyi('SSN输入错误')))
    //     } else {
    //       callback()
    //       this.ssnStatus = true
    //     }
    //   }
    // }
    // 对比验证码
    var ContrastVerificationCode = (rule, value, callback) => {
      this.$api
        .checkVerificationCode({
          occasion: 'register',
          number: this.formregister[this.formregister.tools],
          code: value,
        })
        .then((res) => {
          if (res.success == false) {
            this.regcode = false
            callback(new Error(this.$fanyi(res.msg)))
          } else {
            this.regcode = true
            callback()
          }
        })
    }
    return {
      loading: false,
      showPassword: true,
      ssnStatus: false,
      // 用户登录地址信息
      User_login_address_information: {},
      regcode: false, //验证码是否错误
      step: 1,
      Areacodelist: Areacode,
      formInputTrue: {},
      captcha: '',
      imgurl: "",// 图像验证码
      imgdialogVisible: false,
      // 注册表单
      formregister: {
        state: '',
        tools: 'email',
        countryPhone: '+001'
      },
      agreement: true,
      // 表单验证
      formRules: {
        user_last_name: [
          {
            required: true,
            message: this.$fanyi('请输入姓氏'),
            trigger: 'blur',
          },
          {
            required: true,
            min: 1,
            max: 24,
            message: this.$fanyi('姓氏长度不能大于24位'),
            trigger: ['blur', 'change'],
          },
        ],
        // user_id_card: [
        //   {
        //     validator: verificationText,
        //     trigger: 'blur',
        //   },
        // ],
        state: [
          {
            required: true,
            message: this.$fanyi('请选择州'),
            trigger: ['blur', 'change'],
          },
        ],
        user_name: [
          {
            required: true,
            message: this.$fanyi('请输入名字'),
            trigger: 'blur',
          },
          {
            min: 1,
            max: 24,
            message: '最长支持24个字符',
            trigger: ['blur', 'change'],
          },
        ],
        mobile: [
          {
            required: true,
            message: this.$fanyi('手机号不能为空'),
            trigger: 'blur',
          },
          {
            validator: FieldCalibration,
            trigger: 'blur',
          },
        ],
        num: [
          {
            required: true,
            message: this.$fanyi('请填写验证码'),
            trigger: 'blur',
          },
          {
            validator: ContrastVerificationCode,
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: this.$fanyi('请输入正确的邮箱地址'),
            trigger: 'blur',
          },
          // {
          // required: true,
          //  pattern: /^[a-zA-Z0-9.]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/,
          //  message: this.$fanyi('请输入正确的邮箱地址'),
          //  trigger: ['blur', 'change'],
          // },
          {
            validator: FieldCalibration,
            trigger: 'blur',
          },
          // {
          // validator: emailFormatVerify,
          // trigger: 'blur',
          // },
        ],
        password: [
          //请输入6-16位密码 至少包含大小写字母和数字其中一种 /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,16}$/
          {
            required: true,
            pattern: /^.{6,16}$/,
            message: this.$fanyi('请输入6-请输入6-16位包含大小写字母和数字的密码'),
          },
        ],
        repassword: [
          {
            required: true,
            validator: validatePass2,
            trigger: ['blur', 'change'],
          },
        ],
      },
      regip: '1.1.1.1',
      num: '',
      show: true,
      Countdown: 60,
      options: [{
        value: 'mobile',
        label: 'Phone'
      }, {
        value: 'email',
        label: 'Email'
      }],
    }
  },
  // mounted() {
  // 	this.$jsonp('https://pv.sohu.com/cityjson?ie=utf-8').then((res)=>{

  // 	})
  // },
  created() {

    // this.formregister.tools = 'mobile'
    // 邮箱验证
    // this.formregister.tools = 'email'


  },
  components: {
    Foot,
    HomePageTop,
    UserAgreement,
  },
  methods: {
    // 手动触发表单验证
    validateField(type) {
      if (this.formregister[type])
        this.formregister[type] = this.formregister[type].trim()
      switch (type) {
        case 'password':
          this.$refs.formRef.validateField('repassword', (v) => {
            if (v == '') {
              this.formInputTrue['repassword'] = true
            } else {
              this.formInputTrue['repassword'] = false
            }
            this.$forceUpdate()
          })
          this.$refs.formRef.validateField(type, (v) => {
            if (v == '') {
              this.formInputTrue[type] = true
            } else {
              this.formInputTrue[type] = false
            }
            this.$forceUpdate()
          })
          break
        default:
          this.$refs.formRef.validateField(type, (v) => {
            if (v == '') {
              this.formInputTrue[type] = true
            } else {
              this.formInputTrue[type] = false
            }
            this.$forceUpdate()
          })
      }
    },
    // 显示用户协议弹窗
    protocol() {
      this.$refs.UserAgreement.$refs.dialog.open(
        '',
        () => {
          this.agreement = true
          this.$refs.UserAgreement.$refs.dialog.visible = false
        },
        () => { }
      )
    },
    // 从第一步到第二步
    async getNext() {
      if (!this.agreement)
        return this.$message.error(
          this.$fanyi('使用条款和隐私政策还没有被同意。')
        )
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.formRegister()

        } else {

        }
      })
      var params = new FormData()

    },
    passwordChannelInputLimit(e) {
      this.formregister.password = e.replace(/[^A-Za-z0-9~!@#$%^+-.]/g, '')
    },
    repasswordChannelInputLimit(e) {
      this.formregister.repassword = e.replace(/[^A-Za-z0-9~!@#$%^+-.]/g, '')
    },
    // 确认注册
    formRegister() {
      // this.ip = sessionStorage.getItem("ip");

      var list = {
        user_last_name: this.formregister.user_last_name, //登录名
        password: this.formregister.password, //密码
        user_name: this.formregister.user_name, //真实姓名
        email: this.formregister.email, //邮箱
        mobile: this.formregister.mobile, //手机号
        state: this.formregister.state,
        // user_id_card: this.formregister.user_id_card, //身份证号
        area_code: this.formregister.countryPhone,
        verification: {
          number:
            this.formregister.tools == 'mobile'
              ? this.formregister[this.formregister.tools]
              : this.formregister[this.formregister.tools],
          code: this.formregister.num,
        },
      }

      this.loading = true
      this.$api.register(list).then((res) => {
        this.loading = false
        if (res.code != 0) return
        this.$fun.routerToPage('/registeredSuccess')
        // this.step = 2
      })
    },
    // 短信验证进行图片校验
    sendSMS(e) {

      // 邮箱
      if (!!!this.formregister[this.formregister.tools]) {
        this.$message.warning(this.formregister.tools == 'mobile' ? this.$fanyi('请输入您的手机号') : this.$fanyi('请输入邮箱'))
        return false
      }
      // mobileError
      this.$refs.formRef.validateField(this.formregister.tools, (Error) => {
        // 2种情况：emailError ='请输入邮箱'或者emailError =''（所以返回值为空时，验证通过。返回值非空时，验证失败） mobile手机号

        if (!Error) {



          let list = {}
          if (this.formregister.tools == 'email') {
            list.number = this.formregister.email
            this.Sendverificationcode()
          } else {
            list.number = this.formregister.mobile
            // 校验通过获取图形验证码
            this.$api.codeImg(list).then((res) => {

              if (res.code != 0) return this.$message.error(this.$fanyi(res.msg))
              this.imgurl = res.data.image
              this.captcha = ''
              this.imgdialogVisible = true
            })
          }

        }
      })
    },
    // 确认图形验证码
    cofirmsend() {
      if (this.captcha == '') {
        this.$message.error(this.$fanyi('请输入验证码'))
      } else {
        this.Sendverificationcode()
      }
    },
    // 获取验证码
    async Sendverificationcode() {
      // 校验通过进行图片验证码校验
      let list = {
        occasion: 'register',
        tool: this.formregister.tools,
        captcha: this.captcha, //图形验证码
      }
      if (this.formregister.tools == 'email') {
        list.number = this.formregister.email
      } else {
        list.number = this.formregister.mobile
        list.area_code = this.formregister.countryPhone
      }
      this.show = false
      this.Countdown = 60
      var timer = setInterval(() => {
        this.Countdown--
        if (this.Countdown <= 0) {
          this.show = true
          this.Countdown = 60
          clearInterval(timer)
        }
      }, 1000)

      // 发送图像验证码
      const res = await this.$api.sendVerificationCode(list)
      if (res.code != 0) {
        clearInterval(timer)
        this.$message.error(this.$fanyi(res.msg))
        this.show = true
        this.Countdown = 60,
          this.captcha = ''
        return
      }
      // 60秒倒计时
      this.show = false

      this.imgdialogVisible = false

    }

  },
}
</script>
<style lang="scss">
.el-select__caret {
  color: #222222 !important;
  font-weight: 600;
}

#imgdialog {

  .el-dialog {
    border-radius: 6px;
    min-height: 350px;
    padding: 30px;



    .header-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;

      margin-bottom: 80px;

      .imgbox {
        width: 320px;
        height: 115px;

        img {
          width: 320px;
          height: 115px;
          background-color: pink;
        }


      }

      .Acquire {
        width: 100px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        background-color: #ff730b;
        color: #fff;

        &:hover {
          cursor: pointer;
        }
      }

    }

    .inputbox {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .el-input {
        width: 320px;
      }

      .cofirm {
        width: 100px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        background-color: #ff730b;
        color: #fff;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
@import '../../css/mixin';

.hover {
  background: #FFF2E8;
}

.gongSiYouShi {
  width: $pageWidth;
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin-top: 50px;

  .youShiOpt {
    flex: 1;

    &.one {
      margin-right: 66px;
    }

    &.two {
      margin-right: 37px;
    }

    display: flex;

    align-items: center;

    .optIcon {
      display: block;
      width: 77px;
      height: 77px;
      background: #d3dbe9;
      border-radius: 8px;
      padding: 0;
      margin-right: 17px;
    }

    .optConter {
      flex: 1;

      h4 {
        font-size: 18px;

        font-weight: 400;
        color: #ff730b;
        line-height: 18px;
        margin-bottom: 12px;
      }

      span {
        font-size: 14px;

        font-weight: 300;
        color: #767676;
        line-height: 24px;
      }
    }
  }
}

.flexAndCenter {
  display: flex;
  align-items: center;
}

.main {
  background-color: white;
  width: $pageWidth;
  padding-bottom: 30px;
}

.header {
  height: 30px;
  width: 100%;
  background: #eee;
}

/deep/ .input__inner {
  border-radius: 0;
}

.logo-box {
  display: flex;
  flex-direction: column;
  padding: 40px 0 0 40px;

  h2 {
    font-size: 40px;
    font-weight: 500;
    color: #222222;
    line-height: 60px;
  }

  .fenge {
    width: 48px;
    height: 4px;
    background: #ff730b;
    margin: 10px 0 0 0;
  }

  .login {
    margin: 40px 50px 0 0;

    &.fr {
      $loginIconSize: 20px;

      .oicon {
        display: inline-block;
        text-align: center;
        margin-right: 10px;
        font-size: 16px;

        font-weight: 400;
        color: #ffffff;
        width: $loginIconSize;
        height: $loginIconSize;
        background: #ff730b;
        border-radius: 50%;
      }
    }

    span {
      font-size: 18px;
      font-weight: bold;
      color: #999;
    }

    a {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
  }
}

.register {
  width: 1400px;
  margin: 0 auto;
  padding-bottom: 80px;
  background-color: #fff;

  >div {
    width: 800px;
    min-height: 600px;
    background: #ffffff;
    border: 1px solid white;
    margin: 0 auto;
  }

  .firstStepTitle {
    margin-bottom: 30px;

    .xuYaoXinXi {
      height: 28px;
      font-size: 20px;
      font-weight: 600;
      color: #222222;
      line-height: 28px;
      margin-bottom: 10px;
    }

    .Toast {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
  }

  .firStepForm {
    width: 800px;

    /deep/.el-form-item__error {
      top: 50px;
    }

    >ul {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-wrap: wrap;

      >li {
        width: 379px;
        display: flex;
        flex-direction: column;

        /deep/.el-form-item {
          margin-bottom: 30px;
        }

        // 表单标题样式
        /deep/.el-form-item__label {
          padding: 0;
          text-align: left;
          float: none;
          display: block;
          width: 100%;
          line-height: 18px;
          height: 18px;
          margin-bottom: 10px;

          &::before {
            display: none;
          }
        }

        /deep/.elistrueInput .el-input__inner {
          background: rgba(255, 115, 11, 0.1);
          border: 1px solid #FF730B;
          border: 1px solid #e2e2e2;
          border-radius: 6px !important;
          position: relative;
        }

        input {
          width: 379px;
          height: 48px;
          border: 1px solid #e2e2e2;
          border-radius: 6px;
          background: #ffffff;
          border: 1px solid #dedede;

          border-radius: 6px;
          padding: 0 15px;
          transition: 0.4s;

          &::placeholder {
            color: #b6b6b6;
          }

          &:focus {
            border: 1px solid #ff730b;
            box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
          }

          &.istrueInput {
            background: rgba(255, 115, 11, 0.1);
            border: 1px solid #FF730B;
            border-radius: 6px;
            position: relative;
          }
        }

        .gou {
          position: absolute;
          font-weight: bold;
          right: 13px;
          top: 3px;
          color: #ff730b;

          &.regCodegou {
            right: 165px;
          }

          &.newRegCodegou {
            right: 50px;
          }
        }

        .xuanZeGuoJia {
          width: 100%;
          height: 48px;
          background: rgba(255, 115, 11, 0.1);
          border-radius: 6px;

          /deep/.el-input__inner {
            width: 100%;
            height: 48px;
            border-radius: 6px;
            //border: none;
          }

          /deep/.el-icon-arrow-up {
            font-weight: bold;
            font-size: 15px;
          }
        }

        .istrueInput {
          background: rgba(255, 115, 11, 0.1) !important;
          border: 1px solid #FF730B !important;
          border-radius: 6px;
          position: relative;

          /deep/.el-input__inner {
            width: 100%;
            height: 46px;
            border-radius: 6px;
            background: rgba(255, 115, 11, 0.1) !important;
            border: none;
          }
        }
      }

      .guoJiaQuHao {
        .shouJIHaoCon {
          display: flex;
          align-items: center;

          .quHao {
            width: 100px;
            height: 48px;
            background: #ffffff;
            border: 1px solid #dedede;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            display: flex;
            align-items: center;

            /deep/ .el-select {
              width: 90%;
              height: 40px;

              border-radius: 4px;
              border: none;


              input {
                border: none !important;
                font-size: 14px;
                font-family: Roboto-Regular, Roboto;
                font-weight: 400;
                color: #222222;
                line-height: 16px;
                height: 40px;
                padding-left: 8px;
                padding-right: 8px;
                border-radius: 4px;


              }




            }

            &.istrueInput {
              background: rgba(255, 115, 11, 0.1);
              border: 1px solid #FF730B;
              border-radius: 6px;
              position: relative;
            }
          }

          input {
            width: 312px;
          }
        }
      }

      .yanzhengma {
        .yanzhengmaBox {
          display: flex;
          align-items: center;
          border-radius: 6px !important;

          input {
            height: 48px;
            background: #ffffff;
            border: 1px solid #dedede;
            border-radius: 6px;
            padding-left: 105px;
          }

          .Selectway {
            position: absolute;
            left: 8px;
            top: 50%;
            transform: translateY(-50%);

            /deep/ .el-select {
              width: 80px;
              height: 32px;
              background: #F6F6F6;
              border-radius: 4px;
              border: none;

              input {
                border: none !important;
                font-size: 14px;
                font-family: Roboto-Regular, Roboto;
                font-weight: 400;
                color: #222222;
                line-height: 16px;
                background: #F6F6F6;
                padding-left: 8px;
                padding-right: 8px;
                border-radius: 4px;


              }




            }
          }

          p {
            height: 48px;
            background: transparent;
            position: absolute;
            right: 10px;
            font-size: 14px;
            top: -40px;
            font-weight: 400;
            color: #ff730b;
            height: 30px;
            // line-height: 48px;
          }
        }
      }

      .xieYi {
        // width: 379px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;


        .nextBtn {
          width: 380px;
          height: 48px;
          background: #608bff;
          font-size: 14px;
          color: #ffffff;
          line-height: 20px;
          border-radius: 6px;
        }

        .fuWuXieYi {
          display: flex;
          align-items: center;

          /deep/.el-checkbox {
            width: 16px;
            height: 16px;
            margin-right: 6px;
          }

          /deep/.el-checkbox__inner {
            width: 16px;
            height: 16px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;

            &::after {
              top: 2px;
              left: 5px;
            }


          }

          a {

            font-size: 14px;
            font-weight: 400;
            color: #222222;

            .protocol {
              border-bottom: 1px solid #ff730b;
              color: #ff730b;
            }
          }
        }
      }
    }

    .showregisterInfo {
      width: 379px;
      height: 48px;
      font-size: 13px;
      line-height: 48px;
      border: 1px solid #dedede;
      background-color: #f3f3f3;
      // color: #787777;
      border-radius: 6px;
      padding: 0 10px;
      transition: 0.4s;
    }
  }

  // 表单错误提示样式
}

.stepGroup {
  width: 1400px;
  padding: 40px 0 40px;
  margin: 0 auto;
  background-color: #fff;

  .step {
    width: 1000px;
    display: flex;
    justify-content: cenetr;
    margin: 0 auto;

    .item {
      color: #999;
      text-align: center;

      span {
        display: inline-block;
        width: 18px;

        height: 18px;
        background-color: rgba($color: #ff730b, $alpha: 0.3);
        outline: 4px solid rgba($color: #ff730b, $alpha: 0.3);
        border-color: #ff730b;
        color: white;
        border-radius: 50%;
        font-size: 14px;
        margin-right: 12px;
        line-height: 18px;
      }
    }

    .isStep {
      color: #ff730b;

      span {
        background-color: #ff730b;
        outline-color: rgba($color: #ff730b, $alpha: 0.3);
        color: #fff;
      }
    }

    .successStep {
      span {
        background-color: #608bff;
        outline-color: rgba($color: #608bff, $alpha: 0.3);
        color: #fff;
      }
    }

    .border {
      flex: 1;
      height: 1px;
      background-color: #d7d7d7;
      margin: 14px 28px 0;
    }
  }
}

.registerInformation {
  width: 1400px;

  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  padding: 0 50px 50px;
  flex-direction: column;
  justify-content: space-around;
  background-color: white;

  >.registerInformationCon {
    width: 900px;
    height: 480px;
    background: #ffffff;
    border: 1px solid #d7d7d7;
    margin: 0 auto;

    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    padding: 50px;
    flex-direction: column;
    // justify-content: space-around;
    background-color: white;

    >.registerInformationTitle {
      font-size: 24px;

      color: #000000;
      line-height: 24px;

      margin-bottom: 60px;
    }

    >.registerInformationOpt {
      margin-bottom: 20px;

      >label {
        color: #000000;

        font-size: 14px;
        font-weight: bold;
        width: 314px;
        display: inline-block;
        text-align: right;
        margin-right: 40px;
      }

      >span {
        font-size: 14px;
      }

      &:last-child {
        display: flex;
        justify-content: center;
        margin-top: 30px;

        >button {
          padding: 0 12px;
          height: 26px;
          border-radius: 6px;
          border: 1px solid #ff730b;
          background-color: white;
          color: #ff730b;
          margin-right: 20px;

          &:last-child {
            background-color: #ff730b;
            color: white;
            margin-right: 0;
          }
        }
      }
    }
  }
}

.caoZuo {
  width: 389px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  button {
    border-radius: 0px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #608bff;
    padding: 0 18px;
    line-height: 20px;
    min-width: 120px;
    height: 48px;
    border: 1px solid #608bff;
    white-space: nowrap;
  }

  .shangYiBu {
    background: white;
  }

  .xiaYiBu {
    background: #608bff;
    margin-left: 20px;
    color: #ffffff;
    position: absolute;
    left: 100%;
  }
}

.registration_complete {
  width: 1400px;
  margin: 0 auto;
  text-align: center;
  padding: 0 80px 200px;
  background-color: white;

  >.registration_completeCon {
    margin: 0 auto;
    padding-top: 41px;
    width: 900px;
    height: 306px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;

    .registration_completeConOpt {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      .log {
        width: 180px;
        height: 48px;
        background: #608bff;
        border-radius: 6px;
      }

      &:last-child {
        margin-bottom: 0px;
      }

      .successImg {
        width: 64px;
        height: 64px;
      }

      span {
        font-size: 14px;
        line-height: 20px;
      }

      &.showSpan {
        width: 352px;
        margin-bottom: 60px;
      }

      button {
        width: 120px;
        height: 48px;
        background: #608bff;
        border-radius: 0px;
        font-size: 14px;
        color: #ffffff;
        line-height: 20px;
      }
    }
  }
}

/deep/.el-checkbox__input {
  height: 16px;
}

.ologin {
  cursor: pointer;
  display: inline-block;
  font-size: 55px;
  color: #ff730b;
  margin-bottom: 10px;
}

.passWordInputBox {
  /deep/.el-input__inner {
    height: 48px;
    border-radius: 6px;
  }
}

.allDataShow {
  position: absolute;
  top: 16px;
  right: 20px;
  color: #999999;
  z-index: 999;
}

.obody {
  padding-bottom: 80px;
  background-color: #f7f7f7;

  .registerMainBox {
    border: 1px solid #e2e2e2;
    margin: 40px auto;
    width: 1400px;
    border-radius: 6px;
    overflow: hidden;
  }
}

// 输入框验证失败样式
/deep/.is-error input {
  border-color: #ff730b !important;
}
</style>
